import React, { useState } from 'react';
import { Link } from 'gatsby';
import Cookies from 'universal-cookie';

import StyledCookieBanner from './style';

import { LinkStrong } from '../Typography';

import theme from '../../../theme';

import closeIcon from '../../../../public/images/close_icon.svg';

const cookies = new Cookies();

const CookieBanner = () => {
  const [isActive, setIsActive] = useState(!cookies.get('cookieConsent'));

  const handleCookieConsent = event => {
    event.preventDefault();

    cookies.set('cookieConsent', true, { path: '/', maxAge: 63115200 });
    setIsActive(false);
  };

  return (
    <>
      {isActive && (
        <StyledCookieBanner id="cookieBanner" className={isActive ? theme.classNames.active : ''}>
          <img
            src={closeIcon}
            alt="Close cookie banner"
            tabIndex="0"
            onClick={event => handleCookieConsent(event)}
            className="cookie-banner__close"
          />
          <p className="cookie-banner__title">WE USE COOKIES</p>
          <div className="cookie-banner__description">
            <p>
              This website may use cookies, web beacons, tracking pixels, and similar technologies
              to facilitate the operation of the site, customize the site to you, analyze traffic,
              and provide certain social media features. Please refer to our{' '}
              <Link href="/privacy-policy-and-cookies" className="terms-anchor">
                <LinkStrong>Terms and Conditions</LinkStrong>
              </Link>{' '}
              and{' '}
              for more information. By clicking “I Accept” or “X” on this banner, or using this
              site, you consent to the use of cookies unless you have disabled them.
            </p>
            <button onClick={event => handleCookieConsent(event)}>I Accept</button>
          </div>
        </StyledCookieBanner>
      )}
    </>
  );
};

export default CookieBanner;
