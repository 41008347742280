import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import CookieBanner from '../components/ui-elements/CookieBanner/index';
import Layout from '../components/layout';
import HeadHelmet from '../components/head';
import ContactDiv from '../components/contactUs';
import { StyledBackground } from '../components/ui-elements/backgroundImage';

const IndexPage = ({ data, location }) => {
  const { strapiSsIndexPage } = data;
  return (
    <Layout>
      <HeadHelmet data={strapiSsIndexPage.MetaData} />
      <StyledBackground
        img={strapiSsIndexPage.hero_image.childImageSharp.fluid}
        title={strapiSsIndexPage.title}
        location={location.pathname}
        indexHero
      />

      <div className={'SharedEl__textContent--center'}>
        <div className={'Home__fontSize'}>
          <h2>{strapiSsIndexPage.section_1_title}</h2>
        </div>
        <div>
          <ReactMarkdown>{strapiSsIndexPage.section_1_content}</ReactMarkdown>
        </div>
      </div>
      <div className={'SharedEl__imageHelper'}>
        <Img
          className={'Home__container--indexSecondImageHelper'}
          fluid={strapiSsIndexPage.section_1_image.childImageSharp.fluid}
        />
      </div>
      <div className={'SharedEl__textContent--center SharedEl__listStyle'}>
        <div className={'SharedEl__headingsWidth'}>
          <h3 className={'SharedEl__underline'}>{strapiSsIndexPage.section_2_title}</h3>
        </div>
        <div className={''}>
          <ReactMarkdown className={'SharedEl__gridUL--span SharedEl__ulHelper SharedEl__marginUl'}>
            {strapiSsIndexPage.section_2_content}
          </ReactMarkdown>
        </div>
      </div>
      <div className={'SharedEl__textContent--center SharedEl__listStyle'}>
        <div className={'SharedEl__headingsWidth'}>
          <h3 className={'SharedEl__underline'}>{strapiSsIndexPage.section_3_title}</h3>
        </div>
        <div className={''}>
          <ReactMarkdown className={'SharedEl__gridUL--span SharedEl__ulHelper SharedEl__marginUl'}>
            {strapiSsIndexPage.section_3_content}
          </ReactMarkdown>
        </div>
      </div>
      <div className={'Home__imageHelperDiv'}>
        <Img
          className={'Home__container--indexThirdImageHelper'}
          fluid={strapiSsIndexPage.section_2_image.childImageSharp.fluid}
        />
      </div>
      <ContactDiv />
      <CookieBanner />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    strapiSsIndexPage {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      hero_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_1_title
      section_1_content
      section_1_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_2_title
      section_2_content
      section_2_image {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      section_3_title
      section_3_content
    }
  }
`;
