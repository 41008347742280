import styled from 'styled-components';

import theme from '../../../theme';

const StyledCookieBanner = styled.div`
  display: none;
  animation: fadeOut 0.5s ease;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  &.${theme.classNames.active} {
    animation: fadeIn 1s ease;
    position: fixed;
    bottom: 15px;
    left: 5%;
    right: 5%;
    width: 90%;
    padding: ${theme.spacingM} ${theme.spacingL};
    z-index: 100001;
    display: flex;
    flex-direction: column;
    border-radius: ${theme.borderRadiusBase};
    box-shadow: ${theme.boxShadowBase};
    background: #fff;

    .cookie-banner {
      &__title {
        text-transform: uppercase;
        font-weight: ${theme.fontWeightBold};
        font-size: ${theme.fontSizeS};
        margin-bottom: ${theme.marginXS};
      }

      &__description {
        display: block;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: ${theme.fontSizeXS};
          margin-bottom: 20px;
          margin-right: ${theme.marginM};
          line-height: 1.6;
        }
        button {
          font-size: 18px;
          font-weight: bold;
          height: 2.45rem;
          cursor: pointer;
          margin: 0 5px;
          min-width: 150px;
          background-color: var(--base-color);
          color: white;
          padding: 0px 24px;
          border: 2px solid var(--base-color);
          border-radius: 2.45rem;
        }
        button:hover {
          box-shadow: 4px 4px 4px var(--base-color);
          transform: scale(1.1);
          transition: ease 0.2s;
        }
        .terms-anchor {
          text-decoration: none;
          color: black;
          font-size: 12px;
        }
        @media (min-width: 960px) {
          display: flex;
          p {
            margin-bottom: 0;
          }
        }
      }

      &__close {
        height: 20px;
        width: 20px;
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: ${theme.breakpoints.mobile}) {
    &.${theme.classNames.active} {
      .cookie-banner {
        &__description {
          flex-direction: column;

          p {
            margin-right: 0;
            margin-bottom: ${theme.marginM};
          }
        }
      }
    }
  }
`;

export default StyledCookieBanner;
